import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouterLink } from '@spm/core-ui';
import {
  FooterSection,
  Footer,
  Dropdown,
  DropdownOption,
  FooterLinkList,
  Link,
} from '@govhhs/govhhs-design-system-react';
import LanguageDropdown from '../../NFLanguageDropdown';
import { EnvUtils } from '@spm/core';
import translations from './NFApplicationFooterMessages';
import Paths from '../../../paths';
import { ID } from '../constants';
import logo from '../../../images/NCDHHS-SEAL.png';
import {
  NFOrganisationSelectors
} from '../../../modules/generated';

/**
 * The NFApplicationFooter component handles the rendering of the Application Footer
 * feature incorporating the {@link LanguageDropdown} component to manage the local selection.
 *
 * @param intl The API to format dates, numbers and strings.
 * @param location Locations represent where the app is now, where you want it to go
 * and where it was.
 *
 * @namespace NFApplicationFooter
 * @memberof features.NFApplicationFooter
 */
const NFApplicationFooter = ({ intl, location, isAnbLinkedUser }) => {
  const isANBUserLinked = (isAnbLinkedUser && isAnbLinkedUser.isANBUserLinked);
  const lang = (<LanguageDropdown />);

  // EI-61249-Retrieving global external urls and static text data from NFExternalURLconfig API Starts
  const nfExternalUrlConfig = useSelector(state =>
    NFOrganisationSelectors.fetchNfExternalURLConfig(state)
  );
 
  const getExternalurlData = (configIds) => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    let filteredRecords = null;
    if (nfExternalUrlConfig && nfExternalUrlConfig.dtls) {
      const records = configIds.map(id => nfExternalUrlConfig.dtls.find(config => config.nfExternalURLConfigID === id && config.recordStatus.value === "RST1")).filter(record => record !== undefined);
      if(records && records.length) {
        filteredRecords = records.find(rec => rec.localeIdentifier === selectedLanguage);
      }
      return filteredRecords;
    } else {
      return null;
    }
  }

  const getEnrollBrokerData = getExternalurlData(["69","70"]);
  const getFindDSSData = getExternalurlData(["71","72"]);
  const getDHHSData = getExternalurlData(["73","74"]);
  const getHealthCareData = getExternalurlData(["75","76"]);
   // EI-61249-Retrieving global external urls and static text data from NFExternalURLconfig API Ends
  
  if (
    location.pathname.indexOf(Paths.APPLY) >=0
  )
  {
    return (
    <Footer type="medium">
    <FooterSection
    category="primary"
    className="custom-application-footer"
    languageDropdownWidth="1/4"
    >
    <FooterLinkList listItemsWidth="1/4" listSectionWidth="3/4">
      <RouterLink data-testid={ID.NFAPPLICATION_FOOTER_FAQ_ID} to={Paths.NFFAQ} type="footer">
        {intl.formatMessage(translations.NFApplicationFooter_FAQ_Label)}
      </RouterLink>
      {/* eslint-disable no-restricted-syntax */}
      {/* Enrollment Broker link Start */}
        {nfExternalUrlConfig && getEnrollBrokerData ? (
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_ENROLLMENT_BROKER_ID}
            target="_blank"
            to={getEnrollBrokerData.linkURL}
            >
            {getEnrollBrokerData.linkTextValue}
          </Link>               
        ) : null}     
      {/* Enrollment Broker link End */}

      {/* Find a DSS office link Start */}
      {nfExternalUrlConfig && getFindDSSData ? (
        <Link
          data-testid={ID.NFAPPLICATION_FOOTER_FIND_AN_OFFICE_ID}
          target="_blank"
          to={getFindDSSData.linkURL}
        >
          {getFindDSSData.linkTextValue}
        </Link>
      ) : null}
      {/* Find a DSS office link End */}  

      {/* DHHS link End */}
        {nfExternalUrlConfig && getDHHSData ? (
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_DHHS_ID}
            target="_blank"
            to={getDHHSData.linkURL}
          >
            {getDHHSData.linkTextValue}
          </Link>
        ) : null}
      {/* DHHS link End */}

      {/* Healthcare.gov link End */}
        {nfExternalUrlConfig && getHealthCareData ? (<>
          <Link
            data-testid={ID.NFAPPLICATION_FOOTER_HEALTHCARE_ID}
            target="_blank"
            to={getHealthCareData.linkURL}
          >
            {getHealthCareData.linkTextValue}
          </Link>
        </>) : null}
      {/* Healthcare.gov link End */}

      <RouterLink
        data-testid={ID.NFAPPLICATION_FOOTER_DISCLAIMER_ID}
        to={Paths.NFEPASSDISCLAIMER}
        type="footer"
      >
        {intl.formatMessage(translations.NFApplicationFooter_Disclaimer_Label)}
      </RouterLink>
    </FooterLinkList>
    <img className="custom-ncdhhs-seal-logo" src={logo} alt="NCDHHS seal logo"/>
</FooterSection>
</Footer>);}

const isApplicationOrSubmssionForm =
    new RegExp(`${Paths.APPLY}/-?\\d+`).test(location.pathname) &&
    !/success/.test(location.pathname);


  // Don't display footer for these paths
  if (
    location.pathname === Paths.NFLOGIN ||
    location.pathname === Paths.NFACCOUNTOPTIONS ||
    location.pathname === Paths.NFCREATENCIDACCOUNT
  )
  {
    return null;
  }

  if (isApplicationOrSubmssionForm) {
    return (
      <Footer type="medium">
      {EnvUtils.getEnvironmentProperty('REACT_APP_NF_MULTILOCALE_ENABLED') && !isANBUserLinked ? (
        <FooterSection category="secondary" 
        languageDropdown={lang} 
        languageDropdownWidth="1/6"
        >
          <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6" />
        </FooterSection>) : (
          <FooterSection category="secondary" languageDropdownWidth="1/6">
            <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6" />
        </FooterSection>
        )}
      </Footer>
    );
  }
  return (
    <Footer type="medium">
      <FooterSection
        category="primary"
        className="custom-application-footer"
        data-testid={ID.NFAPPLICATION_FOOTER_DROPDOWN_ID}
        languageDropdown={EnvUtils.getEnvironmentProperty('REACT_APP_NF_MULTILOCALE_ENABLED') && !isANBUserLinked ? lang : null}
        languageDropdownWidth="1/4"
      >
        <FooterLinkList listItemsWidth="1/4" listSectionWidth="3/4">
          <RouterLink data-testid={ID.NFAPPLICATION_FOOTER_FAQ_ID} to={Paths.NFFAQ} type="footer">
            {intl.formatMessage(translations.NFApplicationFooter_FAQ_Label)}
          </RouterLink>
          {/* eslint-disable no-restricted-syntax */}

          {/* Enrollment Broker link Start */}
          {nfExternalUrlConfig && getEnrollBrokerData ? (
            <Link
              data-testid={ID.NFAPPLICATION_FOOTER_ENROLLMENT_BROKER_ID}
              target="_blank"
              to={getEnrollBrokerData.linkURL}
            >
              {getEnrollBrokerData.linkTextValue}
            </Link>
          ) : null}
          {/* Enrollment Broker link End */}

          {/* Find a DSS office link Start */}
          {nfExternalUrlConfig && getFindDSSData ? (
            <Link
              data-testid={ID.NFAPPLICATION_FOOTER_FIND_AN_OFFICE_ID}
              target="_blank"
              to={getFindDSSData.linkURL}
            >
              {getFindDSSData.linkTextValue}
            </Link>
          ) : null}
          {/* Find a DSS office link End */}

          {/* DHHS link End */}
          {nfExternalUrlConfig && getDHHSData ? (
            <Link
              data-testid={ID.NFAPPLICATION_FOOTER_DHHS_ID}
              target="_blank"
              to={getDHHSData.linkURL}
            >
              {getDHHSData.linkTextValue}
            </Link>
          ) : null}
          {/* DHHS link End */}

          {/* Healthcare.gov link End */}
          {nfExternalUrlConfig && getHealthCareData ? (<>
            <Link
              data-testid={ID.NFAPPLICATION_FOOTER_HEALTHCARE_ID}
              target="_blank"
              to={getHealthCareData.linkURL}
            >
              {getHealthCareData.linkTextValue}
            </Link>
          </>) : null}
          {/* Healthcare.gov link End */}

          <RouterLink
            data-testid={ID.NFAPPLICATION_FOOTER_DISCLAIMER_ID}
            to={Paths.NFEPASSDISCLAIMER}
            type="footer"
          >
            {intl.formatMessage(translations.NFApplicationFooter_Disclaimer_Label)}
          </RouterLink>
        </FooterLinkList>
        <img className="custom-ncdhhs-seal-logo" src={logo} alt="NCDHHS seal logo"/>
    </FooterSection>

 
      {/* Don't modify Revision: 86d1ab330. This will be replaced appropriately at runtime. */}
      <p className="footer-text-custom">
        {intl.formatMessage(translations.NFApplicationFooter_BuildVersion_Label)}{' '}
        Revision: 86d1ab330
      </p>
    </Footer>
  );
};

NFApplicationFooter.propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
};

/**
 * Controls the rendering of the Application Footer component incorporating the
 * {@link LanguageDropdown} component to manage the locale selection.
 * @namespace
 */
export default withRouter(injectIntl(NFApplicationFooter));
