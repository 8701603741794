/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFPendingUnifiedProgramsAppExistForUser from api:
 * /v1/ua/nf_authrep/exists_pending_app_for_user
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfAuthrepExistsPendingAppForUserUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_authrep/exists_pending_app_for_user`,
    models.NFPendingUnifiedProgramsAppExistForUser
  );
};

/**
 * Gets NFDisplayRegularEpass from api:
 * /v1/ua/nf_authrep/epass_display_enabled
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfAuthrepEpassDisplayEnabledUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_authrep/epass_display_enabled`,
    models.NFDisplayRegularEpass
  );
};

/**
 * Gets NFProvisionedAuthRep from api:
 * /v1/ua/nf_authrep/authorized_rep
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfAuthrepAuthorizedRepUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_authrep/authorized_rep`,
    models.NFProvisionedAuthRep
  );
};

export {
  fetchNfAuthrepExistsPendingAppForUserUtil,
  fetchNfAuthrepEpassDisplayEnabledUtil,
  fetchNfAuthrepAuthorizedRepUtil,
};
