/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFApplicationPending.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFAPPLICATIONPENDING/FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER`
);

const FETCH_NF_AUTHREP_EPASS_DISPLAY_ENABLED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFAPPLICATIONPENDING/FETCH_NF_AUTHREP_EPASS_DISPLAY_ENABLED`
);

const FETCH_NF_AUTHREP_AUTHORIZED_REP = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFAPPLICATIONPENDING/FETCH_NF_AUTHREP_AUTHORIZED_REP`
);

export {
  FETCH_NF_AUTHREP_EXISTS_PENDING_APP_FOR_USER,
  FETCH_NF_AUTHREP_EPASS_DISPLAY_ENABLED,
  FETCH_NF_AUTHREP_AUTHORIZED_REP,
};
